/* .iframe-position {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    margin: 0;
    padding: 0;
  }
  
  
  .lobby-scene-btn {
    position: fixed;
    bottom: 22%;
    left: 49%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    background-color: transparent;
    z-index: 5;
  }
   */


.iframe-position {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
}


.lobby-scene-btn {
  position: fixed;
  bottom: 22%;
  left: 49%;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  background-color: transparent;
  z-index: 5;
}


.overlayVideo {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1400;
  background-color: rgba(0, 0, 0, 0.8);
}

#video-popup {
  position: fixed;
  z-index: 1500;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 420px;
}

@media(max-width:600px) {
  #video-popup {
    width: 300px;
    height: 309px;
  }
}


#video-popup1 {
  position: fixed;
  z-index: 1500;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 420px;
}

#video-popup2 {
  position: fixed;
  z-index: 1500;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 420px;
}

@media(max-width:600px) {
  #video-popup1 {
    width: 300px;
    height: 309px;
  }
  #video-popup2 {
    width: 300px;
    height: 309px;
  }

  /* .closebtnposition {
    top: -40px !important;
    right: -15px !important;
  } */
}

.videopopup {
  width: 100%;
  margin-bottom: 10px;
}

.startExperianceBtn {
  z-index: 1001;
  color: white;
  width: 250px;
  background-color: #3c53a3;
  outline: none !important;
  border: 0;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 5px 10px 2px rgba(255,255,255, 0.4);
  margin-top: 10px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 25px;
}

.heightExperiance {
  height: 50px;
}

.startExperianceBtn:hover {
  background-color: white;
  border: 2px solid #3c53a3;
  color: #3c53a3;
}

.overlayVideo {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1400;
  background-color: rgba(0, 0, 0, 0.8);
}

.playbtnwidth {
  width: 30px;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.8);
  border-radius: 50%;
}

.closebtnposition {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 10000;
  box-shadow: 0 0 10px;
  border-radius: 50%;
}

.videoTitle {

  text-align: center;
  align-self: center;
  top: -50px;
  position: absolute;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  text-decoration: underline;
}

.closebtnwidth {
  width: 35px;
}

.playvideotext {
  color: #fff;
  /* text-decoration: underline; */
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif !important;
}

/* .quickbtn {
  z-index: 1001;
  color: white;
  background-color: #3c53a3;
  outline: none !important;
  border: 0;
  border-radius: 10px 10px;
  padding: 10px ;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.8);
  margin-top: 10px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif !important;
  position: fixed;
  left: 50%;
  transform: translate(-50%,0);
  bottom: 140px;
  font-size: 1.1rem;
  animation: ripple 1000ms infinite ease-out;

}

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 5px rgba(60, 83, 163, .4), 0px 0px 0px 0px rgba(60, 83, 163, 1);
  }

  100% {
    box-shadow: 0px 0px 0px 3px rgba(117, 117, 255, .2), 0px 0px 0px 10px rgba(255, 255, 255, 0);
  }
}

.quickbtn:hover {
  background-color: white;
  border: 2px solid #3c53a3;
  color: #3c53a3;
} */
.quickbtn {
  display: inline-block;
  padding: 15px;
  margin-right: 5px;
  height: 50px;
  min-width: 100px;
  background: #348aa7;
  border: none;
  outline: none;
  color: white;
  font-family: inherit;
  font-weight: 400;
  font-size: 20px;
  border-radius: 3px;
  box-shadow: 0 5px 0px #348aa7;
  border-bottom: 2px solid #30809b;
}

.btn-glow {
  color: #fff !important;
  display: inline-block;
  background: rgb(60, 83, 163);
  background: -moz-linear-gradient(left, rgba(260, 83, 163, 1) 0%, rgba(60, 83, 163, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(60, 83, 163, 1) 0%, rgba(60, 83, 163, 1) 100%);
  background: linear-gradient(to right, rgba(60, 83, 163, 1) 0%, rgba(60, 83, 163, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8a61f8', endColorstr='#ec5fe7', GradientType=1);
  border: none;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  /* line-height: 56px; */
  letter-spacing: 0.07em;
  text-decoration: none !important;
  text-transform: uppercase;
  padding: 10px 15px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  box-shadow: none;
  animation: glow 3s infinite;
  animation-timing-function: ease-out;
  position: relative;
  border-radius: 10px;
  /* border-top-left-radius: 30px;
  border-top-right-radius: 30px; */
  z-index: 1000;
}

.btn-glow:hover {
  box-shadow: 0 0 0 10px #3daaa200;
  transition: .8s;
  -webkit-transition: .8s;
  z-index: 1000;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 20px #3c53a3;
    text-shadow: 0 0 0px #fff;
  }

  40% {
    box-shadow: 0 0 100px #3c53a3;
    text-shadow: 0 0 20px #fff;
  }

  100% {
    box-shadow: 0 0 20px #3c53a3;
    text-shadow: 0 0 1px #fff;
  }
}

.btn-hover-shine {
  /* position: fixed;
  bottom: 108px;
  left: 50%;
  transform: translate(-50%, 0px);
  outline: none !important;
  z-index: 1000; */
}

.btn-hover-shine:after {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  height: 100%;
  right: 80%;
  width: 1px;
  background-color: #ffffff00;
  box-shadow: 0 0 20px #ffd2fe00, 0 0 10px #ffd2fe00, 0 0 5px #ffd2fe00, 0 0 2px #ffd2fe00;
  opacity: 1;
  z-index: 1000;
}

.btn-hover-shine:hover:after {
  right: 2%;
  background-color: #ffffffff;
  box-shadow: 0 0 30px #ffd2feff, 0 0 15px #ffd2feff, 0 0 7px #ffd2feff, 0 0 3px #ffd2feff;
  opacity: 0;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  z-index: 1000;
}
/* landscape */
/* @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation:landscape) {
  .videopopup{
    height: 100px;
  }
  #video-popup1 {
  height: 120px;
  }
} */
/* mobile landscape */
@media only screen and (max-width: 900px) and (max-height: 400px) and (orientation: landscape) {

  .videopopup{
    height: 100px;
  }
  #video-popup1 {
  height: 120px;
  }
}