.projectBackground {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
}

.layerform {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.positiontwobtn {
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
}

.commonbtn {
  background-color: #F9B64D;
  color: #3c53a3;
  padding: 10px 80px;
  font-size: 1.3rem;
  border: 0;
  outline: none !important;
  width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media(max-width:600px) {
  .commonbtn {
    padding: 10px 10px;
    width: 140px;
  }
}

.borderrightbtnvisitor {
  border-right: 1px solid #3c53a3;
}

.borderleftbtn {
  border-left: 1px solid #3c53a3;
}

/* .radiusleft {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.radiusright {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
} */

.loginformposition {
  position: fixed;
  top: -1000px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 400;
  background: white;
  width: 450px;
  height: 430px;
  border-radius: 10px;
  box-shadow: 0 0 8px #3c53a3;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

@media(min-width:1400px) {
  .loginformposition {
    width: 530px;
    height: 510px;
  }
}

@media(max-width:600px) {
  .loginformposition {
    width: 300px;
    height: 380px;
  }
}

.formlogin {
  position: relative;
}

.headerlogin {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #3c53a3;
  width: 100%;
  height: 80px;
  /* border-bottom: 8px solid #3c53a3; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media(max-width:600px) {
  .headerlogin {
    height: 60px;
  }
}

.switch {
  color: #3c53a3;
  text-decoration: underline;
  font-weight: bold;
}

.logintitle {
  color: #fff;
  margin: 0;
  padding: 0;
}

@media(min-width:1400px) {
  .logintitle {
    font-size: 3rem;
  }

  .headerlogin {
    height: 130px;
  }
}

@media(min-width:1400px) {
  .logintitle {
    font-size: 3rem;
  }

  .headerlogin1 {
    height: 110px !important;
  }
}

.formcontainer {
  position: absolute;
  top: 90px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

@media(max-width:600px) {
  .formcontainer {
    top: 60px;
    padding: 20px;
  }
}

@media(min-width:1400px) {
  .formcontainer {
    top: 150px;
  }
}

@media(min-width:1400px) {
  .formcontainer1 {
    top: 100px !important;
  }
}

#input_container {
  position: relative;
  padding: 5px 10px;
  /* margin: 0; */
  border: 0;
  border-bottom: 1px solid #3c53a3;
  /* border-radius: 50px; */
  width: 100%;
}

.dropdown_container {
  width: 30% !important;
}

.widthnumberinput {
  width: 70% !important;
}

@media(max-width:600px) {
  .dropdown_container {
    width: 40% !important;
  }

  .widthnumberinput {
    width: 60% !important;
  }
}

.countrybox {
  padding-left: 26px;
}

.countrybox select {
  /* padding: 5px 10px; */
  /* width: 270px; */
  width: 100%;
  /* height: 34px; */
  color: #3c53a3;
  border: 0;
  /* border-bottom: 1px solid #3c53a3; */
  background: transparent;
  /* border-bottom: 1px solid #a7a7a7; */
  outline: none;
}

.input-field {
  /* height: 30px; */
  margin: 0;
  padding-left: 30px;
  border: none;
  outline: none !important;
  width: 100%;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;

}

#input_img {
  position: absolute;
  left: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.logintbn {
  background-color: #3c53a3;
  border-radius: 10px;
  width: 100%;
  border: 0;
  outline: none !important;
  padding: 10px 10px;
  color: white;
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 20px;
  font-family: 'Raleway', sans-serif !important;
}

.logintbn:active {
  transform: scale(0.9);
}

.marginbottmflex {
  margin-bottom: 20px;
}

.toptobottom {
  -moz-animation: ToptoBottom 0.8s forwards;
  -webkit-animation: ToptoBottom 0.8s forwards;
  -o-animation: ToptoBottom 0.8s forwards;
  animation: ToptoBottom 0.8s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@keyframes ToptoBottom {
  0% {
    top: -1000px;
  }

  100% {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) {
  .loginformposition {
    margin-top: 30px;
  }
}

.backImg {
  width: 55px;
  right: 20px;
  position: fixed;
  z-index: 200;
  top: 20px;
  cursor: pointer;
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

@media(max-width:700px) {
  .backImg {
    width: 40px;
  }
}

.backImg:hover {
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
  transform: scale(0.8);
}

.marginrightentrybtn {
  margin-right: 20px;
}

.widthmob {
  width: 40px;
}

@media(max-width:600px) {
  .widthmob {
    width: 20px;
  }

  .marginrightentrybtn {
    margin-right: 10px;
  }
}

.widthbeinglogo {
  width: 200px;
}

@media(max-width:600px) {
  .widthbeinglogo {
    width: 150px;
  }
}

.logoposition {
  position: fixed;
  top: 20px;
  left: 20px;
  background: white;
  padding: 10px;
  border-radius: 10px;
  z-index: 10;
}

@media(max-width:600px) {
  .logoposition {
    top: 10px;
    left: 10px;
  }
}

.flexinput {
  display: flex;
  width: 100%;
}

footer p a,
footer p a:focus {
  color: #fff;
  transition: all 0.3s;
  text-decoration: underline;
}

footer p a:hover,
footer p a:focus:hover {
  color: #fff;
}

.marginrightcheckbox {
  margin-right: 10px;
}

.producttext {
  color: #3c53a3;
  font-size: 0.8rem;
}

.margintopcheckbox {
  margin-top: 20px;
}

.opacityszero {
  opacity: 0;
}

.prefrencebgcolor {
  background-color: #aaa;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
}

::placeholder {
  color: #3c53a3;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #3c53a3;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #3c53a3;
}

.sizeverifytext {
  font-size: 1.2rem;
  text-align: center;
}


.smallpopupposition {
  position: fixed;
  top: -1000px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 400;
  background: white;
  width: 450px;
  height: 250px;
  border-radius: 10px;
  box-shadow: 0 0 8px #3c53a3;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

@media(min-width:1400px) {
  .smallpopupposition {
    width: 530px;
    height: 250px;
  }
}

@media(max-width:600px) {
  .smallpopupposition {
    width: 300px;
    height: 180px;
  }
}